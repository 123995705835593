
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList, parseTime } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerGuideList, exportBloggerGuideList, saveBloggerGuide, setBloggerGuideStatus, deleteBloggerGuide } from "@/api/request/blogger";

//组件
@Component({
  name: "BloggerGuideSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    blogger_id_str: "",

    //临时数据
    level: 0,
    online: -1,
    country: "",
    nick_name: "",
    blogger_id: 0,
  };

  //在线状态
  private onlineList: any[] = [
    { id: -1, name: "全部状态" },
    { id: 0, name: "下线" },
    { id: 1, name: "在线" },
    { id: 2, name: "离线" },
  ];

  //等级列表
  private levelList: any[] = [
    { id: 0, name: "全部博主" },
    { id: 1, name: "见习博主" },
    { id: 2, name: "新晋博主" },
    { id: 3, name: "优秀博主" },
    { id: 4, name: "金牌博主" },
    { id: 5, name: "铂金博主" },
    { id: 6, name: "钻石博主" },
    { id: 7, name: "皇冠博主" },
    { id: 8, name: "首席博主" },
  ];

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerGuideList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理新增博主
  private addHandler(): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: "", //排序
      blogger_id_str: "", //博主ID

      //数据
      id: 0, //ID
      sort: 0, //排序
      blogger_id: 0, //博主ID
    };

    //显示界面
    this.dialogVisible = true;
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportBloggerGuideList(this.listQuery);

    //保存数据
    saveAs(data, "博主引导设置列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: String(row.sort), //排序
      blogger_id_str: String(row.blogger_id), //博主ID

      //数据
      id: row.id, //ID
      sort: row.sort, //排序
      blogger_id: row.blogger_id, //博主ID
    };

    //显示界面
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm("确定删除博主引导设置？", "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await deleteBloggerGuide({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //获取时间
  private getTimeStr(time: number): any {
    return parseTime(time * 1000);
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //处理状态
  private async handleStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 2 * 1000);

    //获取数据
    await setBloggerGuideStatus({ id: row.id, status: status });

    //获取列表
    this.getList();
  }

  //-------------------------------- 添加/编辑 任务 ------------------------------
  //定义变量
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //表单数据
  private form: any = {
    //临时数据
    sort_str: "", //排序
    blogger_id_str: "", //博主ID

    //数据
    id: 0, //ID
    sort: 0, //排序
    blogger_id: 0, //博主ID
  };

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //保存按钮
  private async handlePush() {
    //数据处理
    if (Number(this.form.blogger_id_str) == 0) return this.$message.error("请输入正确的博主ID");

    //数据赋值
    this.form.sort = Number(this.form.sort_str);
    this.form.blogger_id = Number(this.form.blogger_id_str);

    //保存任务
    await saveBloggerGuide(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏加载
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogVisible = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }
}
