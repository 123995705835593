
//本地引入
import { scrollTo } from "@/utils/scroll-to";

//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Pagination",
})

//界面函数
export default class extends Vue {
  //组件参数
  @Prop({ default: 1 }) private page!: number;
  @Prop({ default: 20 }) private limit!: number;
  @Prop({ required: true }) private total!: number;
  @Prop({ default: false }) private hidden!: boolean;
  @Prop({ default: true }) private background!: boolean;
  @Prop({ default: true }) private autoScroll!: boolean;
  @Prop({ default: () => [10, 20, 30, 50] }) private pageSizes!: number[];
  @Prop({ default: "total, sizes, prev, pager, next, jumper" }) private layout!: string;

  //获取页面尺寸
  get pageSize() {
    return this.limit;
  }
  //获取当前页面
  get currentPage() {
    return this.page;
  }

  //设置页面尺寸
  set pageSize(value) {
    this.$emit("update:limit", value);
  }

  //设置当前页面
  set currentPage(value) {
    this.$emit("update:page", value);
  }

  //处理页面尺寸改变
  handleSizeChange(value: number): void {
    //发送消息
    this.$emit("pagination", { page: this.currentPage, limit: value });

    //滚动到最上面
    if (this.autoScroll) scrollTo(0, 800);
  }

  //处理页面改变
  handleCurrentChange(value: number): void {
    //发送消息
    this.$emit("pagination", { page: value, limit: this.pageSize });

    //滚动到最上面
    if (this.autoScroll) scrollTo(0, 800);
  }
}
