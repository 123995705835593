//当前位置
const position = () => {
  return document.documentElement.scrollTop || (document.body.parentNode as HTMLElement).scrollTop || document.body.scrollTop;
};

//移动动画
const move = (amount: number) => {
  document.documentElement.scrollTop = amount;
  (document.body.parentNode as HTMLElement).scrollTop = amount;
  document.body.scrollTop = amount;
};

//请求动画帧
const requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

//缓动进出动画
const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

//滚动到指定位置
export const scrollTo = (to: number, duration: number, callback?: Function) => {
  //定义变量
  let currentTime: number = 0;
  const increment: number = 20;
  const start: number = position();
  const change: number = to - start;
  duration = typeof duration === "undefined" ? 500 : duration;

  //动态滚动
  const animateScroll = function () {
    //时间赋值
    currentTime += increment;

    //缓动距离赋值
    const val: number = easeInOutQuad(currentTime, start, change, duration);

    //移动
    move(val);

    //滚动中
    if (currentTime < duration) {
      //请求动画帧
      requestAnimFrame(animateScroll);
    }
    //滚动结束
    else {
      //回调函数
      if (callback && typeof callback === "function") callback();
    }
  };

  //动态滚动
  animateScroll();
};
